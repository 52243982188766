import React from 'react'
import Layout from '../components/layout'

export default function Resources({location}) {
    return (
        <Layout location={location}>
            {/* <div className="mb-8"></div>
            <div className="grid grid-cols-1 md:grid-cols-12">
                <div className="mb-16 col-span-4">
                    <h1 className="mb-2">Tools I use</h1>
                    <h3 className="">Glyphs</h3>
                    <h3 className="">Figma</h3>
                    <h3 className="">Photoshop</h3>
                    <h3 className="">Illustrator</h3>
                </div>
                <div className="mb-16 col-span-4">
                    <h1 className="mb-2">Website Stack</h1>
                    <p>This website was designed and coded by me using the following technlogy stack</p>
                    <h3 className="">Gatsby</h3>
                    <h3 className="">Tailwind</h3>
                    <h3 className="">MDX</h3>
                    <h3 className="">Framer Motion</h3>
                </div>

                <div className="mb-16 col-span-8">
                    <h1 className="mb-2">Music</h1>
                    <p>Playlists by me, others. Every genre included.</p>
                    <h3 className="">Gatsby</h3>
                    <h3 className="">Tailwind</h3>
                    <h3 className="">MDX</h3>
                    <h3 className="">Framer Motion</h3>
                </div>
                
                <div className="mb-16 col-span-6">
                    <h1 className="mb-2">Branding Books</h1>
                    <h3 className="">The 22 laws of immutable branding</h3>
                    <h3 className="">The Brand Gap</h3>
                    <h3 className="">ZAG: The #1 Strategy of High-Performance Brands </h3>
                </div>
                <div className="mb-16 col-span-6">
                    <h1 className="mb-2">Lettering Books</h1>
                    <h3 className="">Logotypes</h3>
                    <h3 className="">The Golden Secrets of Lettering</h3>
                    <h3 className="">In Progress: See Inside a Lettering Artist's Sketchbook and Process, from Pencil to Vector</h3>
                    <h3 className="">House Industries Lettering Manual</h3>
                    <h3 className="">Handstyle Lettering</h3>
                </div>
                <div className="mb-16 col-span-5 space-y-4">
                    <h1 className="mb-2">Non-fiction Books</h1>
                    <h3 className="">Shoe Dog</h3>
                    <h3 className="">The E-Myth Revisited: Why Most Small Businesses Don't Work and What to Do</h3>
                    <h3 className="">Hooked: How to Build Habit-Forming Products</h3>
                    <h3 className="">Predictably Irrational, The Hidden Forces That Shape Our Decisions</h3>
                    <h3 className="">Purple Cow, Transform Your Business by Being Remarkable</h3>
                    <h3 className="">Start With Why: How Great Leaders Inspire Everyone To Take Action</h3>
                    <h3 className="">ReWork</h3>
                    <h3 className="">Open, an autobiography.</h3>
                    <h3 className="">Deep work</h3>
                    <h3 className="">Atomic Habits</h3>
                    <h3 className="">The power of Habit</h3>
                    <h3 className="">Thinking, Fast and Slow</h3>
                    <h3 className="">Outliers: The Story of Success</h3>
                    <h3 className="">Creativity, Inc.</h3>
                    <h3 className="">Essentialism: The Disciplined Pursuit of Less</h3>
                    <h3 className="">The War of Art</h3>
                    <h3 className="">Make Time: How to focus on what matters every day</h3>
                    <h3 className="">Ego is the Enemy : The Fight to Master Our Greatest Opponent</h3>
                    <h3 className="">Steal Like An Artist</h3>
                    <h3 className="">Show Your Work!</h3>
                    <h3 className="">Keep Going: 10 Ways to Stay Creative in Good Times and Bad</h3>

                </div>
                <div className="mb-16 col-span-6">
                    <h1 className="mb-2">Selected Articles</h1>
                    <h3 className="">Logotypes</h3>
                    <h3 className="">The Golden Secrets of Lettering</h3>
                    <h3 className="">In Progress: See Inside a Lettering Artist's Sketchbook and Process, from Pencil to Vector</h3>
                    <h3 className="">House Industries Lettering Manual</h3>
                    <h3 className="">Handstyle Lettering</h3>
                </div>
            </div> */}

        </Layout>
    )
}
